import { useQuery } from '@tanstack/react-query';
import { UserInterfaceNotificationCountsData, UserInterfaceNotificationCountsIdsData } from '@bladebinge/types';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { PROXY_ROUTES } from '../../../server/api-proxy/ui-proxy-routes';
import { normalizedFetch } from '../../../server/api-proxy/utils/normalized-fetch';

const NOTIFICATION_COUNTS_POLL_INTERVAL = 30000;

const fetchUserInterfaceNotificationCountsFn =
    ({ userId, signal }: { userId?: string | null; signal?: AbortSignal }) =>
    async (): Promise<{
        countsData: UserInterfaceNotificationCountsData;
        idsData: UserInterfaceNotificationCountsIdsData;
    }> => {
        const response = await normalizedFetch({
            endpoint: PROXY_ROUTES.fetchUserUserInterfaceNotificatonCounts.build({ userId }),
            options: {
                method: 'GET',
                ...(signal ? { signal } : {})
            }
        });

        const { error: { message: asyncError = '' } = {} } = response as { error?: { message: string } };

        if (asyncError) {
            throw new Error(asyncError);
        }

        return response;
    };

export const useLoggedInUserUserInterfaceNotificationCounts = (
    { hasMounted, userId }: { hasMounted: boolean; userId?: string | null },
    signal?: AbortSignal
) =>
    useQuery({
        enabled: hasMounted && Boolean(userId),
        queryKey: uiCacheKeyBuilderMap.loggedInUserUserInterfaceNotificationCounts({ loggedInUserId: userId }),
        queryFn: fetchUserInterfaceNotificationCountsFn({
            userId,
            signal
        }),
        placeholderData: (prev) => prev,
        refetchInterval: NOTIFICATION_COUNTS_POLL_INTERVAL,
        refetchOnReconnect: 'always',
        refetchOnWindowFocus: 'always'
    });
