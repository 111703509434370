import currency from 'currency.js';
import { PurchaseOfferFinancialSettlement } from '@bladebinge/types';
import { FINANCING_PROCESSING_FEE } from '../constants/commission-rates';

export const calculateBuyerFinancingCharge = ({
    merchandiseTotal = 0,
    shippingTotal = 0
}: {
    merchandiseTotal?: number;
    shippingTotal?: number;
}) => currency(merchandiseTotal).add(currency(shippingTotal)).multiply(FINANCING_PROCESSING_FEE).value;

export const calculateOfferSettlementBuyerFinancingCharge = ({
    purchaseOfferFinancialSettlement
}: {
    purchaseOfferFinancialSettlement: PurchaseOfferFinancialSettlement;
}) => {
    const {
        merchandiseTotal = 0,
        shippingTotal = 0,
        sellerRoleTradeMerchandiseTotal = 0,
        buyerRoleTradeMerchandiseTotal = 0
    } = purchaseOfferFinancialSettlement;

    return currency(merchandiseTotal)
        .add(currency(shippingTotal))
        .subtract(currency(sellerRoleTradeMerchandiseTotal))
        .add(currency(buyerRoleTradeMerchandiseTotal))
        .multiply(FINANCING_PROCESSING_FEE).value;
};
