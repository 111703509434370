import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { MessageGraph } from '@bladebinge/types';
import { displayDate } from '../../../../utils/display-date';
import { FlaggingIconButton } from '../../../atoms/FlaggingIconButton';
import { useMe } from '../../../../context/me/me-context';
import { useBulkUpdateReadStatus } from '../../../../hooks/react-query/messaging/bulk-update-read-status';
import { getMessageBodyDisplayText } from '../utils/get-message-body-display-text';
import { ZoomableMessageImages } from '../ZoomableMessageImages';
import { MessageCreatorAvatar } from './MessageCreatorAvatar';
import { paperStyles } from './constants/thread-message-styles';

export const ThreadMessage = ({
    message,
    viewAsUserId
}: {
    readonly message: MessageGraph;
    readonly viewAsUserId?: string;
}) => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();
    const viewFromPerspectiveUserId = viewAsUserId ?? loggedInUserId;

    const displayAs = viewFromPerspectiveUserId === message.creatorId ? 'sender' : 'recipient';
    const { id: messageId, createdAt, creatorId, images = [], messageBody, recipients } = message;

    const currentUserRecipientRecord = (recipients ?? []).find(
        ({ recipientUserId }) => recipientUserId === viewFromPerspectiveUserId
    );
    const { isPending: isUpdatingReadStatus, mutate: updateReadStatusMutate } = useBulkUpdateReadStatus({
        userId: loggedInUserId
    });

    const isRead = currentUserRecipientRecord?.isRead;

    const { ref, inView } = useInView({
        threshold: 0.8,
        skip: isRead || displayAs === 'sender',
        initialInView: false
    });
    const fromCurrentLoggedInUser = displayAs === 'sender';
    const hasImages = images.length > 0;

    /* eslint-disable react-hooks/exhaustive-deps */
    // not sure if this causes a memory issue, we explicitly want this request to fire on unmount
    // so not creating abort controller in this case
    useEffect(() => {
        if (viewAsUserId) {
            // do not update read status if viewing from a different user
            return;
        }

        const updateThreadReadStatuses = () => {
            updateReadStatusMutate({
                userId: viewFromPerspectiveUserId,
                bulkIsReadMapping: {
                    [messageId]: true
                }
            });
        };

        return () => {
            const shouldMarkRead = inView && !isRead && !isUpdatingReadStatus;

            if (!shouldMarkRead) {
                return;
            }

            updateThreadReadStatuses();
        };
    }, [inView, viewAsUserId]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const visibleMessageBody = getMessageBodyDisplayText({
        hideAttachmentText: true,
        messageBody,
        t
    });

    const imagesContent = hasImages ? (
        <Grid size={9}>
            <Paper sx={paperStyles[displayAs]}>
                <ZoomableMessageImages
                    align={fromCurrentLoggedInUser ? 'right' : 'left'}
                    createdAt={createdAt}
                    images={images}
                />
            </Paper>
        </Grid>
    ) : null;

    return (
        <Grid
            ref={ref}
            container
            justifyContent={fromCurrentLoggedInUser ? 'flex-end' : 'flex-start'}
            alignItems="center"
            spacing={1}
            sx={{ p: 1, width: '100%' }}
        >
            <Grid size={{ xs: 2, sm: 1 }}>
                {!fromCurrentLoggedInUser && <MessageCreatorAvatar message={message} />}
            </Grid>
            {visibleMessageBody && (
                <Grid size={{ xs: 8, sm: 9 }}>
                    <Paper sx={paperStyles[displayAs]}>
                        <Grid container>
                            <Grid size={11}>
                                <Typography variant="caption">
                                    {createdAt ? displayDate(createdAt) : t('common:messaging.just_now')}
                                </Typography>
                                <Typography
                                    className="thread-message-body"
                                    variant="body1"
                                    sx={{ fontWeight: 'bold', whiteSpace: 'pre-wrap' }}
                                >
                                    {visibleMessageBody}
                                </Typography>
                            </Grid>
                            <Grid size={1}>
                                {!fromCurrentLoggedInUser && (
                                    <FlaggingIconButton
                                        flaggedEntityType="MESSAGE"
                                        flaggedEntityId={messageId}
                                        flaggedEntityOwnerId={creatorId}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            )}
            {
                /* if there is no message to show, show images next to avatar */
                !visibleMessageBody && imagesContent
            }
            <Grid size={1}>{fromCurrentLoggedInUser && <MessageCreatorAvatar message={message} />}</Grid>
            {
                /* if there a message to show, show images below the message */
                visibleMessageBody && (
                    <Grid container justifyContent={fromCurrentLoggedInUser ? 'flex-end' : 'flex-start'} size={12}>
                        {!fromCurrentLoggedInUser && <Grid size={1}> </Grid>}
                        {imagesContent}
                        {fromCurrentLoggedInUser && <Grid size={1}> </Grid>}
                    </Grid>
                )
            }
        </Grid>
    );
};
