import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    UserInterfaceNotificationCountsData,
    UserInterfaceNotificationCountsIdsData,
    UserInterfaceNotificationMenuItemTypes
} from '@bladebinge/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { useMe } from '../me/me-context';
import { useHasMounted } from '../../hooks/use-has-mounted';
import { useLoggedInUserUserInterfaceNotificationCounts } from '../../hooks/react-query/logged-in-user-hooks/user-logged-in-user-user-interface-notification-counts';
import { bulkDismissUserInterfaceNotifications } from '../../server/api-proxy/ui-mutation-fns/dismiss-user-interface-notification';

const EMPTY_COUNTS_DATA = {
    NEW_MESSAGE: 0,
    SHIPMENT_AWAITING_SHIPMENT: 0,
    SHIPMENT_SHIPPING_LABEL_REQUIRED: 0,
    RATING_RECEIVED: 0,
    BUYER_RATING_NEEDED: 0,
    SELLER_RATING_NEEDED: 0,
    NEW_PURCHASE_OFFER: 0,
    NEW_COUNTER_OFFER: 0,
    PURCHASE_OFFER_ACCEPTED: 0,
    PURCHASE_OFFER_DECLINED: 0,
    PRICE_DROP: 0,
    OUTGOING_PAYMENT_ERROR: 0,
    TOTAL: 0
};

const EMPTY_IDS_DATA = {
    BUYER_RATING_NEEDED: [],
    NEW_COUNTER_OFFER: [],
    NEW_MESSAGE: [],
    NEW_PURCHASE_OFFER: [],
    OUTGOING_PAYMENT_ERROR: [],
    PRICE_DROP: [],
    PURCHASE_OFFER_ACCEPTED: [],
    PURCHASE_OFFER_DECLINED: [],
    RATING_RECEIVED: [],
    SELLER_RATING_NEEDED: [],
    SHIPMENT_AWAITING_SHIPMENT: [],
    SHIPMENT_SHIPPING_LABEL_REQUIRED: []
};

const DISMISSAL_IDS_BY_UI_NOTIFICATION_TYPE: {
    [displayType in UserInterfaceNotificationMenuItemTypes]: keyof UserInterfaceNotificationCountsIdsData;
} = {
    BUYER_FEEDBACK_REQUESTED: 'BUYER_RATING_NEEDED',
    FEEDBACK: 'RATING_RECEIVED',
    NEW_COUNTER_OFFER: 'NEW_COUNTER_OFFER',
    NEW_MESSAGE: 'NEW_MESSAGE',
    NEW_PURCHASE_OFFER: 'NEW_PURCHASE_OFFER',
    OUTGOING_PAYMENT_ERROR: 'OUTGOING_PAYMENT_ERROR',
    PRICE_DROP: 'PRICE_DROP',
    PURCHASE_OFFER_ACCEPTED: 'PURCHASE_OFFER_ACCEPTED',
    PURCHASE_OFFER_DECLINED: 'PURCHASE_OFFER_DECLINED',
    SELLER_FEEDBACK_REQUESTED: 'SELLER_RATING_NEEDED',
    SHIPMENT_AWAITING_SHIPMENT: 'SHIPMENT_AWAITING_SHIPMENT',
    SHIPMENT_SHIPPING_LABEL_REQUIRED: 'SHIPMENT_SHIPPING_LABEL_REQUIRED'
};

const EMPTY_SORTED_COUNTS_DATA: [displayType: UserInterfaceNotificationMenuItemTypes, countValue: number][] = [
    ['SHIPMENT_AWAITING_SHIPMENT', 0],
    ['SHIPMENT_SHIPPING_LABEL_REQUIRED', 0],
    ['BUYER_FEEDBACK_REQUESTED', 0],
    ['SELLER_FEEDBACK_REQUESTED', 0],
    ['NEW_PURCHASE_OFFER', 0],
    ['NEW_COUNTER_OFFER', 0],
    ['PURCHASE_OFFER_ACCEPTED', 0],
    ['PURCHASE_OFFER_DECLINED', 0],
    ['NEW_MESSAGE', 0],
    ['PRICE_DROP', 0],
    ['FEEDBACK', 0],
    ['OUTGOING_PAYMENT_ERROR', 0]
];

interface UserInterfaceNotificationsContext {
    countsData: UserInterfaceNotificationCountsData;
    feedbackTotalCount: number;
    sortedCountsData: [displayType: UserInterfaceNotificationMenuItemTypes, countValue: number][];
    handleBulkDismissal: (dismissalType: UserInterfaceNotificationMenuItemTypes | 'DISMISS_ALL') => void;
    invalidateNotificationsCounts(): void;
    isDismissingNotifications: boolean;
    isLoading: boolean;
    isPending: boolean;
    toggleMenu: () => void;
    isMenuOpen: boolean;
}

const userInterfaceNotificationsContext = createContext<UserInterfaceNotificationsContext>({
    countsData: EMPTY_COUNTS_DATA,
    feedbackTotalCount: 0,
    sortedCountsData: EMPTY_SORTED_COUNTS_DATA,
    handleBulkDismissal() {},
    invalidateNotificationsCounts() {},
    isDismissingNotifications: false,
    isLoading: false,
    isPending: false,
    toggleMenu() {},
    isMenuOpen: false
});

export const { Consumer: UserInterfaceNotificationsConsumer } = userInterfaceNotificationsContext;
const { Provider } = userInterfaceNotificationsContext;

export const UserInterfaceNotificationsProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const { id: loggedInUserId } = useMe();
    const queryClient = useQueryClient();
    const hasMounted = useHasMounted();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [idsData, setIdsData] = useState<UserInterfaceNotificationCountsIdsData>(EMPTY_IDS_DATA);

    const {
        data: { countsData = EMPTY_COUNTS_DATA, idsData: serverIdsData = EMPTY_IDS_DATA } = {
            countsData: EMPTY_COUNTS_DATA,
            idsData: EMPTY_IDS_DATA
        },
        isLoading: countsLoading,
        isPending: countsPending
    } = useLoggedInUserUserInterfaceNotificationCounts({
        hasMounted,
        userId: loggedInUserId
    });

    useEffect(() => setIdsData(serverIdsData), [serverIdsData]);

    const sortedCountsData: [displayType: UserInterfaceNotificationMenuItemTypes, countValue: number][] = useMemo(
        () => [
            ['SHIPMENT_AWAITING_SHIPMENT', countsData.SHIPMENT_AWAITING_SHIPMENT],
            ['SHIPMENT_SHIPPING_LABEL_REQUIRED', countsData.SHIPMENT_SHIPPING_LABEL_REQUIRED],
            ['BUYER_FEEDBACK_REQUESTED', countsData.BUYER_RATING_NEEDED],
            ['SELLER_FEEDBACK_REQUESTED', countsData.SELLER_RATING_NEEDED],
            ['NEW_MESSAGE', countsData.NEW_MESSAGE],
            ['NEW_PURCHASE_OFFER', countsData.NEW_PURCHASE_OFFER],
            ['NEW_COUNTER_OFFER', countsData.NEW_COUNTER_OFFER],
            ['PURCHASE_OFFER_ACCEPTED', countsData.PURCHASE_OFFER_ACCEPTED],
            ['PURCHASE_OFFER_DECLINED', countsData.PURCHASE_OFFER_DECLINED],
            ['PRICE_DROP', countsData.PRICE_DROP],
            ['FEEDBACK', countsData.RATING_RECEIVED],
            ['OUTGOING_PAYMENT_ERROR', countsData.OUTGOING_PAYMENT_ERROR]
        ],
        [countsData]
    );

    // memoize total feedback count for UserMenu Feedback tab
    const feedbackTotalCount = useMemo(
        () => countsData.BUYER_RATING_NEEDED + countsData.SELLER_RATING_NEEDED + countsData.RATING_RECEIVED,
        [countsData]
    );

    const invalidateNotificationsCounts = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: uiCacheKeyBuilderMap.loggedInUserUserInterfaceNotificationCounts({
                loggedInUserId
            })
        });
    }, [loggedInUserId, queryClient]);

    const bulkDismissUiNotificationsMutation = useMutation({
        mutationFn: bulkDismissUserInterfaceNotifications,
        onSuccess(_data, variables) {
            invalidateNotificationsCounts();
        }
    });

    const { isPending: isDismissingNotifications } = bulkDismissUiNotificationsMutation;

    const handleBulkDismissal = (dismissalType: UserInterfaceNotificationMenuItemTypes | 'DISMISS_ALL') => {
        if (!loggedInUserId) {
            return;
        }

        if (dismissalType === 'DISMISS_ALL') {
            const idsToDismiss = Object.values(idsData).flat();
            if (idsToDismiss.length <= 0) {
                return;
            }

            bulkDismissUiNotificationsMutation.mutate({
                notificationIds: idsToDismiss,
                userId: loggedInUserId
            });
            return;
        }

        const keyOfIdsToDismiss = DISMISSAL_IDS_BY_UI_NOTIFICATION_TYPE[dismissalType];
        const idsToDismiss = idsData[keyOfIdsToDismiss] ?? [];

        if (idsToDismiss.length <= 0) {
            return;
        }

        bulkDismissUiNotificationsMutation.mutate({
            notificationIds: idsToDismiss,
            userId: loggedInUserId
        });
    };

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <Provider
            value={{
                countsData,
                feedbackTotalCount,
                sortedCountsData,
                handleBulkDismissal,
                invalidateNotificationsCounts,
                isDismissingNotifications,
                isLoading: countsLoading,
                isPending: countsPending,
                toggleMenu,
                isMenuOpen
            }}
        >
            {children}
        </Provider>
    );
};

export const useUserInterfaceNotificationsContext = () => useContext(userInterfaceNotificationsContext);
