import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { calculateBuyerFinancingCharge } from '@bladebinge/web-service-common/src/utils/calculate-buyer-financing-charge';
import type {
    CartSalesTaxDataApiResponse,
    ListingGraph,
    ShoppingCartData,
    ShoppingCartItem,
    ShoppingCartShipment
} from '@bladebinge/types';
import {
    getCartDataFromLocalStorage,
    setCartDataInLocalStorage
} from '@bladebinge/web-service-common/src/utils/local-storage/local-storage-util';
import { useBulkListings } from '../../hooks/react-query/use-bulk-listings';
import { getCalculatedCartSalesTax } from '../../server/api-proxy/ui-mutation-fns/get-calculated-cart-sales-tax';
import { calculateSubtotal } from './utils/calculate-subtotal';
import { calculateSalesTaxTotal } from './utils/calculate-sales-tax-total';
import { calculateShippingTotal } from './utils/calculate-shipping-total';
import {
    getCartWithAddedItems,
    getCartWithAddedShipments,
    getCartWithRemovedItems,
    getCartWithRemovedShipments,
    updateShipmentTaxData
} from './utils/cart-contents-utils';
import { EMPTY_CART } from './utils/constants';

interface CartContext {
    addItemsToCart: (items: ShoppingCartItem | ShoppingCartItem[]) => void;
    addShipmentsToCart: (shipmentSelectionData: {
        includeShippingInsurance: boolean;
        shipments: ShoppingCartShipment | ShoppingCartShipment[];
    }) => void;
    allItemsHaveFreeShipping?: boolean;
    buyerFinancingCharge: number;
    calculateSalesTaxError?: string;
    cartData: ShoppingCartData;
    cartId?: string | null;
    cartItemsCount: number;
    cartItemsListingGraphs: ListingGraph[];
    clearCart: () => void;
    clearCartShipments: () => void;
    clearTaxData: () => void;
    currency: string;
    hasCurrentTaxData?: boolean;
    isCalculatingSalesTax?: boolean;
    subtotal: number;
    shipToAddressId?: string;
    shippingTotal: number;
    showCartDrawer: boolean;
    taxTotal: number;
    total: number;

    removeItemsFromCart: (items: ShoppingCartItem | ShoppingCartItem[]) => void;
    removeShipmentsFromCart: (shipments: ShoppingCartShipment | ShoppingCartShipment[]) => void;
    setShowCartDrawer: (open: boolean) => void;
    setIncludeFinancingProcessingFee: (v: boolean | null) => void;
    setIncludeShippingInsurance: (v: boolean | null) => void;
}

const shoppingCartContext = createContext<CartContext>({
    addItemsToCart(items: ShoppingCartItem | ShoppingCartItem[]) {},
    addShipmentsToCart(shipmentSelectionData: {
        includeShippingInsurance: boolean;
        shipments: ShoppingCartShipment | ShoppingCartShipment[];
    }) {},
    buyerFinancingCharge: 0,
    calculateSalesTaxError: undefined,
    cartId: null,
    cartItemsListingGraphs: [],
    cartItemsCount: 0,
    cartData: EMPTY_CART,
    clearCart() {},
    clearCartShipments() {},
    clearTaxData() {},
    currency: 'USD',
    hasCurrentTaxData: false,
    isCalculatingSalesTax: false,
    removeItemsFromCart(items: ShoppingCartItem | ShoppingCartItem[]) {},
    removeShipmentsFromCart(shipments: ShoppingCartShipment | ShoppingCartShipment[]) {},
    subtotal: 0,
    shippingTotal: 0,
    shipToAddressId: '',
    showCartDrawer: false,
    taxTotal: 0,
    total: 0,
    setShowCartDrawer() {},
    setIncludeFinancingProcessingFee(v: boolean | null) {},
    setIncludeShippingInsurance(v: boolean | null) {}
});

const { Provider } = shoppingCartContext;

const countCartItems = (cartData: ShoppingCartData) =>
    Object.values(cartData?.items ?? {}).reduce((acc, { quantity }) => acc + quantity, 0);

export const ShoppingCartContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const [cartId, setCartId] = useState<string | null>(null);
    const [cartData, setCartData] = useState<ShoppingCartData>(getCartDataFromLocalStorage());
    const [cartItemsCount, setCartItemsCount] = useState<number>(countCartItems(cartData));
    const { items: itemsInCart = {}, shipments: shipmentsInCart = {} } = useMemo(() => cartData, [cartData]);
    const [includeFinancingProcessingFee, setIncludeFinancingProcessingFee] = useState<boolean | null>(null);
    const [includeShippingInsurance, setIncludeShippingInsurance] = useState<boolean | null>(null);
    const [shippingTotal, setShippingTotal] = useState<number>(calculateShippingTotal(shipmentsInCart));
    const [buyerFinancingCharge, setBuyerFinancingCharge] = useState<number>(0);
    const [subtotal, setSubtotal] = useState<number>(calculateSubtotal(itemsInCart));
    const [taxTotal, setTaxTotal] = useState<number>(calculateSalesTaxTotal(shipmentsInCart));
    const [showCartDrawer, setShowCartDrawer] = useState<boolean>(false);

    const setAllCartDataProps = (updatedCartData: ShoppingCartData) => {
        const { cartId = '' } = updatedCartData;

        if (cartId) {
            setCartId(cartId);
        }

        setCartData(updatedCartData);
        setCartDataInLocalStorage(updatedCartData);
        setCartItemsCount(countCartItems(updatedCartData));
    };

    const calculateCartSalesTaxMutation = useMutation({
        mutationFn: getCalculatedCartSalesTax,
        onSuccess({ cartId, cartTaxData }: CartSalesTaxDataApiResponse) {
            const updatedCartData = updateShipmentTaxData({
                cartId,
                currentCart: cartData,
                taxMapBySellerIdUnderscoreFromAddressId: cartTaxData
            });
            setAllCartDataProps(updatedCartData);
        }
    });

    const {
        error: calculateSalesTaxError,
        isPending: isCalculatingSalesTax,
        isSuccess: hasCalculatedSalesTax,
        reset: resetTaxMutation
    } = calculateCartSalesTaxMutation;

    const hasCurrentTaxData = useMemo(
        () => hasCalculatedSalesTax && !isCalculatingSalesTax && !calculateSalesTaxError,
        [hasCalculatedSalesTax, isCalculatingSalesTax, calculateSalesTaxError]
    );

    const isTaxFetchInProgress = useMemo(
        () => calculateSalesTaxError || isCalculatingSalesTax,
        [calculateSalesTaxError, isCalculatingSalesTax]
    );

    const { data: cartItemsListingGraphs = [], refetch: refetchCartItemListingGraphs } = useBulkListings({
        ids: Object.keys(itemsInCart)
    });

    useEffect(() => {
        refetchCartItemListingGraphs();
    }, [itemsInCart, refetchCartItemListingGraphs]);

    const currency = useMemo(() => {
        const currencyInferredFromFirstItem = cartItemsListingGraphs?.[0]?.currency;
        return currencyInferredFromFirstItem ?? 'USD';
    }, [cartItemsListingGraphs]);

    const allItemsHaveFreeShipping = useMemo(() => {
        const shipmentValues = Object.values(shipmentsInCart);
        return shipmentValues.length > 0
            ? shipmentValues.reduce<boolean>(
                  (acc, { hasSellerPaidShipping = false }) => hasSellerPaidShipping && acc,
                  true
              )
            : false;
    }, [shipmentsInCart]);

    useEffect(() => {
        setSubtotal(calculateSubtotal(itemsInCart));
        setTaxTotal(calculateSalesTaxTotal(shipmentsInCart));
        setShippingTotal(calculateShippingTotal(shipmentsInCart));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItemsListingGraphs, shipmentsInCart]);

    useEffect(() => {
        if (includeFinancingProcessingFee === null || includeFinancingProcessingFee === false) {
            setBuyerFinancingCharge(0);
            return;
        }

        setBuyerFinancingCharge(calculateBuyerFinancingCharge({ merchandiseTotal: subtotal, shippingTotal }));
    }, [includeFinancingProcessingFee, subtotal, shippingTotal]);

    const addItemsToCart = (items: ShoppingCartItem | ShoppingCartItem[]) => {
        // normalize this to an array
        const itemsAdded = Array.isArray(items) ? items : [items];
        const updatedCartData = getCartWithAddedItems(cartData, itemsAdded);
        setAllCartDataProps(updatedCartData);
    };

    const addShipmentsToCart = ({
        includeShippingInsurance,
        shipments
    }: {
        includeShippingInsurance?: boolean;
        shipments: ShoppingCartShipment | ShoppingCartShipment[];
    }) => {
        // tax calculations are void when item contents change
        resetTaxMutation();
        setIncludeShippingInsurance(includeShippingInsurance ?? null);
        // normalize this to an array
        const shipmentsAdded = Array.isArray(shipments) ? shipments : [shipments];
        const updatedCartData = getCartWithAddedShipments(cartData, shipmentsAdded);
        setAllCartDataProps(updatedCartData);
    };

    // CALCULATE BUYER TAXES ON CART WITH ALL NECESSARY SHIPPING RATES SELECTED
    useEffect(() => {
        if (isTaxFetchInProgress) {
            return;
        }

        // we need to know financing status to properly calculate taxes
        if (includeFinancingProcessingFee === null) {
            return;
        }

        const itemsCount = Object.keys(cartData.items).length;
        const hasItems = itemsCount > 0;
        const rateSelectedShipmentCount = Object.keys(cartData.shipments).length;
        const hasShipments = rateSelectedShipmentCount > 0;

        const hasAllRequiredShippingRatesSelected = Object.values(cartData.shipments).reduce<boolean>(
            (hasAllRates, shipment) => {
                const { rateData, hasSellerPaidShipping } = shipment;
                const chosenRateObject = rateData?.objectId;
                if (!hasSellerPaidShipping && !chosenRateObject) {
                    return false;
                }

                return hasAllRates;
            },
            true
        );

        if (!hasShipments || !hasItems) {
            return;
        }

        if (hasItems && !hasCurrentTaxData && hasAllRequiredShippingRatesSelected) {
            calculateCartSalesTaxMutation.mutate({
                cartData,
                includeFinancingProcessingFee: includeFinancingProcessingFee === true,
                includeShippingInsurance: includeShippingInsurance === true
            });
        }
    }, [
        calculateCartSalesTaxMutation,
        cartData,
        hasCurrentTaxData,
        includeFinancingProcessingFee,
        includeShippingInsurance,
        isTaxFetchInProgress
    ]);

    const clearCartShipments = useCallback(() => {
        const { items } = cartData;

        const updatedCartData = {
            items,
            shipments: {}
        };

        setAllCartDataProps(updatedCartData);
    }, [cartData]);

    const clearCart = useCallback(() => {
        setAllCartDataProps(EMPTY_CART);
    }, []);

    const clearTaxData = useCallback(() => {
        setTaxTotal(0);
        resetTaxMutation();
    }, [resetTaxMutation]);

    const removeItemsFromCart = (items: ShoppingCartItem | ShoppingCartItem[]) => {
        const itemsRemoved = Array.isArray(items) ? items : [items];
        const updatedCartData = getCartWithRemovedItems(cartData, itemsRemoved);
        setAllCartDataProps(updatedCartData);
    };

    const removeShipmentsFromCart = (shipments: ShoppingCartShipment | ShoppingCartShipment[]) => {
        const shipmentsRemoved = Array.isArray(shipments) ? shipments : [shipments];
        const updatedCartData = getCartWithRemovedShipments(cartData, shipmentsRemoved);
        setAllCartDataProps(updatedCartData);
    };

    return (
        <Provider
            value={{
                addItemsToCart,
                addShipmentsToCart,
                allItemsHaveFreeShipping,
                buyerFinancingCharge,
                calculateSalesTaxError: calculateSalesTaxError?.message,
                cartData,
                cartId,
                cartItemsListingGraphs,
                cartItemsCount,
                clearCart,
                clearCartShipments,
                clearTaxData,
                currency,
                hasCurrentTaxData,
                isCalculatingSalesTax,
                removeItemsFromCart,
                removeShipmentsFromCart,
                showCartDrawer,
                setShowCartDrawer,
                subtotal,
                shippingTotal,
                setIncludeFinancingProcessingFee,
                setIncludeShippingInsurance,
                taxTotal,
                total: subtotal + shippingTotal + (taxTotal ?? 0) + (buyerFinancingCharge ?? 0)
            }}
        >
            {children}
        </Provider>
    );
};

export const useShoppingCartContext = () => useContext(shoppingCartContext);
