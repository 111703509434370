import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { MODERATION_FLAG_TYPES } from '@bladebinge/web-service-common/src/constants/moderation-flags';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';

export const FlaggingGuidelines = () => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1} justifyContent="center">
            <Paper sx={{ m: 1.5, p: 1.5 }}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 12
                    }}
                >
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        {t('common:moderation_flags.guidelines.heading')}
                    </Typography>
                    <Typography variant="body2">
                        {t('common:moderation_flags.moderation_flagging_disclaimer')}
                    </Typography>
                    <Typography variant="h5" sx={{ mt: 2 }}>
                        {t('common:moderation_flags.explanation_of_flag_types')}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                </Grid>
                {MODERATION_FLAG_TYPES.sort().map((flagType) => (
                    <Grid key={`${flagType}_condition_help`} size={12}>
                        <Typography
                            variant="body1"
                            sx={{ mt: 1, fontFamily: headingFont.style.fontFamily, textDecoration: 'underline' }}
                        >
                            {t(`common:moderation_flags.flag_types.${flagType}`)}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2.5 }}>
                            {t(`common:moderation_flags.flag_types_help.${flagType}`)}
                        </Typography>
                    </Grid>
                ))}
            </Paper>
        </Grid>
    );
};
