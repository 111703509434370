import pino from 'pino';
import querystring, { ParsedQuery } from 'query-string';

// eslint-disable-next-line
type RouteBuilder = (params?: any) => string;

interface UiProxyApiMethodSupportDefinition {
    [proxyMethodName: string]: {
        apiRoute: string;
        build: RouteBuilder;
        method: 'GET' | 'POST' | 'PUT' | 'DELETE';
        proxyFile?: string;
    };
}

// TODO - define all proxy routes to the API this way
export const PROXY_ROUTES: UiProxyApiMethodSupportDefinition = {
    // account/ ROUTES
    fetchUserAccount: {
        apiRoute: 'account/:id',
        build: ({ userId }: { userId: string }) => `account/${userId}`,
        method: 'GET',
        proxyFile: 'account.ts'
    },
    fetchLoggedInUser: {
        apiRoute: 'logged_in_user/:id',
        build: ({ userId }: { userId: string }) => `logged_in_user/${userId}`,
        method: 'GET',
        proxyFile: 'account.ts'
    },
    fetchLoggedInUserActivePurchaseOfferListingIds: {
        apiRoute: 'users/:id/',
        build: ({ loggedInUserId }: { loggedInUserId: string }) =>
            `user/${loggedInUserId}/active_purchase_offer_listing_ids`,
        method: 'GET'
    },
    fetchLoggedInUserPurchaseOfferCheckoutData: {
        apiRoute: '/user/:id/purchase_offer/:purchaseOfferId/checkout',
        build: ({ loggedInUserId, purchaseOfferId }: { loggedInUserId: string; purchaseOfferId: string }) =>
            `user/${loggedInUserId}/purchase_offer/${purchaseOfferId}/checkout`,
        method: 'GET'
    },

    // downloads routes
    downloadSalesHistory: {
        apiRoute: 'users/:id/',
        build: ({ loggedInUserId }: { loggedInUserId: string }) => `user/${loggedInUserId}/sales_history_download`,
        method: 'POST'
    },

    // address ROUTES
    createAddress: {
        apiRoute: 'address/new',
        build: () => 'address/new',
        method: 'POST',
        proxyFile: 'addresses.ts'
    },
    fetchAddressById: {
        apiRoute: 'address/:addressId',
        build: ({ addressId }: { addressId: string }) => `address/${addressId}`,
        method: 'GET',
        proxyFile: 'addresses.ts'
    },
    fetchSellerAddressInformation: {
        apiRoute: 'seller/:sellerId/address/:addressId',
        build: ({ addressId, sellerId }: { addressId: string; sellerId: string }) =>
            `seller/${sellerId}/address/${addressId}`,
        method: 'GET',
        proxyFile: 'addresses.ts'
    },
    fetchUserActiveProfileAddressBook: {
        apiRoute: 'user/:id/active_profile_address_book',
        build: ({ userId }: { userId: string }) => `user/${userId}/active_profile_address_book`,
        method: 'GET',
        proxyFile: 'user-profile-addresses.ts'
    },
    fetchUserPriceDropNotifications: {
        apiRoute: '/user/:userId/price_drop_notifications',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/price_drop_notifications${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-interface-notifications.ts'
    },
    fetchUserProfileAddressBook: {
        apiRoute: '/user_profiles/:userProfileId/address_book',
        build: ({ userProfileId }: { userProfileId: string }) => `user_profiles/${userProfileId}/address_book`,
        method: 'GET',
        proxyFile: 'user-profile-addresses.ts'
    },
    fetchUserUserInterfaceNotificatonCounts: {
        apiRoute: '/user/:userId/ui_notification_counts',
        build: ({ userId }: { userId: string }) => `user/${userId}/ui_notification_counts`,
        method: 'GET',
        proxyFile: 'user-interface-notification.ts'
    },
    fetchUserPurchaseRatingsNeeded: {
        apiRoute: '/user/:userId/purchases/feedback_needed',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/purchases/feedback_needed${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-ratings.ts'
    },
    fetchUserRatingsGiven: {
        apiRoute: '/user/:userId/ratings_given',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/ratings_given${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-ratings.ts'
    },
    fetchUserRatingsReceived: {
        apiRoute: '/user/:userId/ratings_received',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/ratings_received${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-ratings.ts'
    },
    fetchUserSalesRatingsNeeded: {
        apiRoute: '/user/:userId/sales/feedback_needed',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/sales/feedback_needed${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-ratings.ts'
    },
    // auth ROUTES
    login: {
        apiRoute: 'auth/login',
        build: () => 'auth/login',
        method: 'POST',
        proxyFile: 'authorization.ts'
    },
    logout: {
        apiRoute: 'auth/logout',
        build: () => 'auth/logout',
        method: 'GET',
        proxyFile: 'authorization.ts'
    },

    // calculate_purchase_sales_taxes
    fetchCalculatedSalesTax: {
        apiRoute: 'calculate_purchase_sales_taxes',
        build: () => 'calculate_purchase_sales_taxes',
        method: 'POST',
        proxyFile: 'purchase-processor.ts'
    },
    fetchCalculatedOfferCheckoutSalesTax: {
        apiRoute: `user/:id/purchase_offer_financial_settlement/:settlementId/calculate_offer_checkout_sales_tax`,
        build: ({ settlementId, loggedInUserId }: { settlementId: string; loggedInUserId: string }) =>
            `user/${loggedInUserId}/purchase_offer_financial_settlement/${settlementId}/calculate_offer_checkout_sales_tax`,
        method: 'POST'
    },

    // images ROUTES
    fetchBulkImagesById: {
        apiRoute: 'images/bulk_by_id',
        build: () => 'images/bulk_by_id',
        method: 'POST',
        proxyFile: 'images.ts'
    },
    fetchImageById: {
        apiRoute: 'images/:id',
        build: ({ id }: { id: string }) => `images/${id}`,
        method: 'GET',
        proxyFile: 'images.ts'
    },

    // listings ROUTES
    fetchBulkListingsById: {
        apiRoute: 'listings/bulk_by_id',
        build: () => 'listings/bulk_by_id',
        method: 'POST',
        proxyFile: 'listings.ts'
    },
    fetchListingById: {
        apiRoute: 'listings/:id',
        build: ({ listingId }: { listingId: string }) => `listings/${listingId}`,
        method: 'GET',
        proxyFile: 'listings.ts'
    },
    fetchListingStatsById: {
        apiRoute: 'listings/:id/stats',
        build: ({ listingId }: { listingId: string }) => `listings/${listingId}/stats`,
        method: 'GET',
        proxyFile: 'listings.ts'
    },
    fetchListings: {
        apiRoute: 'listings',
        build({ queryParams = {} }: { queryParams: ParsedQuery }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-purchases.ts'
    },
    postListingViewById: {
        apiRoute: 'listings/:id/view',
        build: ({ listingId }: { listingId: string }) => `listings/${listingId}/view`,
        method: 'POST',
        proxyFile: 'listings.ts'
    },

    // order
    createPaymentIntent: {
        apiRoute: 'create_payment_intent',
        build: () => 'create_payment_intent',
        method: 'POST',
        proxyFile: 'purchase-processor.ts'
    },
    processPendingPaymentIntentOrder: {
        apiRoute: 'payment_intent_order',
        build: () => 'payment_intent_order',
        method: 'POST',
        proxyFile: 'purchase-processor.ts'
    },

    // order_shipment
    fetchOrderShipmentShippingRates: {
        apiRoute: 'order_shipment/:orderShipmentId/shipping_rates',
        build: ({ orderShipmentId }: { orderShipmentId: string }) => `order_shipment/${orderShipmentId}/shipping_rates`,
        method: 'POST',
        proxyFile: 'order-shipments.ts'
    },
    fetchOrderShipmentGraphById: {
        apiRoute: '/user/:userId/order_shipment/:orderShipmentId',
        build: ({ orderShipmentId, userId }: { orderShipmentId: string; userId: string }) =>
            `user/${userId}/order_shipment/${orderShipmentId}`,
        method: 'GET',
        proxyFile: 'order-shipments.ts'
    },

    // rating
    fetchBulkRatingsById: {
        apiRoute: 'ratings/bulk_by_id',
        build: () => 'ratings/bulk_by_id',
        method: 'POST',
        proxyFile: 'ratings.ts'
    },
    fetchRatingById: {
        apiRoute: 'ratings/:ratingId',
        build: ({ ratingId }: { ratingId: string }) => `ratings/${ratingId}`,
        method: 'GET',
        proxyFile: 'ratings.ts'
    },

    // search_filters
    fetchAllSearchFilters: {
        apiRoute: 'search_filters',
        build: () => `search_filters`,
        method: 'GET',
        proxyFile: 'search-filters.ts'
    },

    // shipping_rates
    fetchShippingRateQuotes: {
        apiRoute: 'shipping_rates',
        build: () => 'shipping_rates',
        method: 'POST',
        proxyFile: 'purchase-processor.ts'
    },

    // sitemap
    fetchSitemapData: {
        apiRoute: 'sitemap/link_data',
        build: () => 'sitemap/link_data',
        method: 'GET',
        proxyFile: 'sitemap.ts'
    },

    // ui-error
    uiError: {
        apiRoute: 'ui_error',
        build: () => 'ui_error',
        method: 'POST',
        proxyFile: 'errors.ts'
    },

    // user/ ROUTES
    addUserFavoriteListings: {
        apiRoute: 'user/:id/add_favorite_listings',
        build: ({ userId }: { userId: string }) => `user/${userId}/add_favorite_listings`,
        method: 'POST',
        proxyFile: 'user-favorite-listings.ts'
    },
    activateUserListing: {
        apiRoute: 'user/:id/listing/:listingId/activate',
        build: ({ listingId, userId }: { listingId: string; userId: string }) =>
            `user/${userId}/listing/${listingId}/activate`,
        method: 'POST',
        proxyFile: 'listings.ts'
    },
    bulkDismissUserInterfaceNotifications: {
        apiRoute: 'user/:id/bulk_dismiss_ui_notifications',
        build: ({ userId }: { notificationId: string; userId: string }) =>
            `user/${userId}/bulk_dismiss_ui_notifications`,
        method: 'POST'
    },
    bulkUpdateMessageReadStatuses: {
        apiRoute: 'user/:id/message_read_status_update',
        build: ({ userId }: { userId: string }) => `user/${userId}/message_read_status_update`,
        method: 'POST',
        proxyFile: 'messages.ts'
    },
    cancelUserListing: {
        apiRoute: 'user/:id/listing/:listingId/cancel',
        build: ({ listingId, userId }: { listingId: string; userId: string }) =>
            `user/${userId}/listing/${listingId}/cancel`,
        method: 'POST',
        proxyFile: 'listings.ts'
    },
    createMessage: {
        apiRoute: 'user/:id/message',
        build: ({ userId }: { userId: string }) => `user/${userId}/message`,
        method: 'POST',
        proxyFile: 'messages.ts'
    },
    createModerationFlag: {
        apiRoute: 'user/:id/moderation_flag',
        build: ({ userId }: { userId: string }) => `user/${userId}/moderation_flag`,
        method: 'POST',
        proxyFile: 'moderation-flags.ts'
    },
    createPurchaseOffer: {
        apiRoute: 'user/:id/purchase_offer/create',
        build: ({ userId }: { userId: string }) => `user/${userId}/purchase_offer/create`,
        method: 'POST',
        proxyFile: 'create-purchase-offer.ts'
    },
    cancelOrderShipment: {
        apiRoute: 'user/:id/order_shipment/:orderShipmentId/cancellation',
        build: ({ orderShipmentId, userId }: { orderShipmentId: string; userId: string }) =>
            `user/${userId}/order_shipment/${orderShipmentId}/cancellation`,
        method: 'POST',
        proxyFile: 'order-shipments.ts'
    },
    confirmPurchaseOfferOrderShipmentShippingRate: {
        apiRoute: 'user/:id/purchase_offer/:purchaseOfferId/order_shipment/:orderShipmentId/confirm_shipping_rate',
        build: ({
            orderShipmentId,
            purchaseOfferId,
            userId
        }: {
            orderShipmentId: string;
            purchaseOfferId: string;
            userId: string;
        }) =>
            `user/${userId}/purchase_offer/${purchaseOfferId}/order_shipment/${orderShipmentId}/confirm_shipping_rate`,
        method: 'POST',
        proxyFile: 'order-shipments.ts'
    },
    confirmOrderShipmentShippingRate: {
        apiRoute: 'user/:id/order_shipment/:orderShipmentId/confirm_shipping_rate',
        build: ({ orderShipmentId, userId }: { orderShipmentId: string; userId: string }) =>
            `user/${userId}/order_shipment/${orderShipmentId}/confirm_shipping_rate`,
        method: 'POST',
        proxyFile: 'order-shipments.ts'
    },
    createPurchaseOfferSettlementPaymentIntent: {
        apiRoute: `user/:id/purchase_offer_financial_settlement/:settlementId/create_payment_intent`,
        build: ({ settlementId, loggedInUserId }: { settlementId: string; loggedInUserId: string }) =>
            `user/${loggedInUserId}/purchase_offer_financial_settlement/${settlementId}/create_payment_intent`,
        method: 'POST'
    },
    createDraftListing: {
        apiRoute: 'user/:id/user_profile/:userProfileId/listing',
        build: ({ userId, userProfileId }: { userId: string; userProfileId: string }) =>
            `user/${userId}/user_profile/${userProfileId}/listing`,
        method: 'POST',
        proxyFile: 'listings.ts'
    },
    createPlaidLinkToken: {
        apiRoute: 'user/:id/plaid_item/token_create',
        build: ({ userId }: { userId: string }) => `user/${userId}/plaid_item/token_create`,
        method: 'GET',
        proxyFile: 'account.ts'
    },
    createReferrerRequest: {
        apiRoute: 'user/:userId/referrer_application',
        build: ({ userId }: { userId: string }) => `user/${userId}/referrer_application`,
        method: 'POST',
        proxyFile: 'referral.ts'
    },
    createUserRating: {
        apiRoute: 'user/:id/rating',
        build: ({ raterId }: { raterId: string }) => `user/${raterId}/rating`,
        method: 'POST',
        proxyFile: 'ratings.ts'
    },
    deactivateUserListing: {
        apiRoute: 'user/:id/listing/:listingId/deactive',
        build: ({ listingId, userId }: { listingId: string; userId: string }) =>
            `user/${userId}/listing/${listingId}/deactivate`,
        method: 'POST',
        proxyFile: 'listings.ts'
    },
    acceptPurchaseOffer: {
        apiRoute: '/user/:id/purchase_offer/:purchaseOfferId/decline',
        build: ({ purchaseOfferId, userId }: { purchaseOfferId: string; userId: string }) =>
            `user/${userId}/purchase_offer/${purchaseOfferId}/accept`,
        method: 'POST'
    },
    cancelPurchaseOffer: {
        apiRoute: '/user/:id/purchase_offer/:purchaseOfferId/cancel',
        build: ({ purchaseOfferId, userId }: { purchaseOfferId: string; userId: string }) =>
            `user/${userId}/purchase_offer/${purchaseOfferId}/cancel`,
        method: 'GET'
    },
    declinePurchaseOffer: {
        apiRoute: '/user/:id/purchase_offer/:purchaseOfferId/decline',
        build: ({ purchaseOfferId, userId }: { purchaseOfferId: string; userId: string }) =>
            `user/${userId}/purchase_offer/${purchaseOfferId}/decline`,
        method: 'GET'
    },
    deleteDraftListing: {
        apiRoute: 'user/:id/listing/:listingId',
        build: ({ listingId, userId }: { listingId: string; userId: string }) => `user/${userId}/listing/${listingId}`,
        method: 'DELETE',
        proxyFile: 'listings.ts'
    },
    deleteLinkedPlaidBankAccount: {
        apiRoute: 'user/:id/plaid_item',
        build: ({ userId }: { userId: string }) => `user/${userId}/plaid_item`,
        method: 'DELETE',
        proxyFile: 'account.ts'
    },
    deleteListingImage: {
        apiRoute: 'user/:id/listing/:listingId/image/:imageId',
        build: ({ imageId, listingId, userId }: { imageId: string; listingId: string; userId: string }) =>
            `user/${userId}/listing/${listingId}/image/${imageId}`,
        method: 'DELETE',
        proxyFile: 'images.ts'
    },
    deleteModerationFlag: {
        apiRoute: 'user/:id/moderation_flag/:moderationFlagId',
        build: ({ moderationFlagId, userId }: { moderationFlagId: string; userId: string }) =>
            `user/${userId}/moderation_flag/${moderationFlagId}`,
        method: 'DELETE',
        proxyFile: 'moderation-flags.ts'
    },
    deleteUserInterfaceNotification: {
        apiRoute: 'user/:id/ui_notifications/:notificationId',
        build: ({ notificationId, userId }: { notificationId: string; userId: string }) =>
            `user/${userId}/ui_notification/${notificationId}`,
        method: 'DELETE'
    },
    dismissAllUserReceivedRatingsNotifications: {
        apiRoute: '/user/:id/dismiss_ratings_received_ui_notifications',
        build: ({ userId }: { userId: string }) => `user/${userId}/dismiss_ratings_received_ui_notifications`,
        method: 'GET'
    },
    fetchUserOrderDetailByOrderId: {
        apiRoute: 'user/:id/order/:orderId',
        build: ({ orderId, userId }: { orderId: string; userId: string }) => `user/${userId}/order/${orderId}`,
        method: 'GET',
        proxyFile: 'purchase-processor.ts'
    },
    linkPlaidBankAccount: {
        apiRoute: 'user/:id/plaid_item/token_exchange',
        build: ({ userId }: { userId: string }) => `user/${userId}/plaid_item/token_exchange`,
        method: 'POST',
        proxyFile: 'account.ts'
    },
    fetcAllUserProfilesForUser: {
        apiRoute: '/user/:userId/user_profiles',
        build: ({ userId }: { userId: string }) => `user/${userId}/user_profiles`,
        method: 'GET',
        proxyFile: 'user-profiles.ts'
    },
    fetchMessageInboxReceivedMessages: {
        apiRoute: 'user/:id/received_messages',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/received_messages${paramString}`;
        },
        method: 'GET',
        proxyFile: 'messages.ts'
    },
    fetchMessageOuboxSentMessages: {
        apiRoute: 'user/:id/sent_messages',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/sent_messages${paramString}`;
        },
        method: 'GET',
        proxyFile: 'messages.ts'
    },
    fetchMessageThread: {
        apiRoute: 'user/:id/message_thread',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/message_thread${paramString}`;
        },
        method: 'POST',
        proxyFile: 'messages.ts'
    },
    fetchPurchaseOfferById: {
        apiRoute: '/purchase_offer/:purchaseOfferId',
        build: ({ purchaseOfferId }: { purchaseOfferId: string }) => `purchase_offer/${purchaseOfferId}`,
        method: 'GET'
    },
    fetchPriceDroppedListings: {
        apiRoute: 'price_dropped_listings',
        build({ queryParams }: { queryParams: ParsedQuery }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `price_dropped_listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-notification-price-dropped-listings.ts'
    },
    fetchRecentlySoldListings: {
        apiRoute: 'recently_sold_listings',
        build({ queryParams }: { queryParams: ParsedQuery }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `recently_sold_listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'listings.ts'
    },
    fetchUserActiveProfile: {
        apiRoute: 'user/:id/active_profile_graph',
        build: ({ userId }: { userId: string }) => `user/${userId}/active_profile_graph`,
        method: 'GET',
        proxyFile: 'account.ts'
    },
    fetchUserFavoriteListingIds: {
        apiRoute: 'user/:id/favorite_listing_ids',
        build({ queryParams, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/favorite_listing_ids${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-favorite-listings.ts'
    },
    fetchUserFavoriteListings: {
        apiRoute: 'user/:id/favorite_listings',
        build({ queryParams, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/favorite_listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-favorite-listings.ts'
    },
    fetchUserImageUploads: {
        apiRoute: 'user/:id/image_uploads',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/image_uploads${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-image-uploads.ts'
    },
    fetchUserPurchaseOffers: {
        apiRoute: 'user/:id/purchase_offers',
        build({ queryParams, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/purchase_offers${paramString}`;
        },
        method: 'GET',
        proxyFile: 'purchase-offers.ts'
    },
    fetchUserOfferIds: {
        apiRoute: 'user/:id/purchase_offer_ids',
        build({ queryParams, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/purchase_offer_ids${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-favorite-listings.ts'
    },
    fetchUserPreferences: {
        apiRoute: 'user/:id/preferences',
        build: ({ userId }: { userId: string }) => `user/${userId}/preferences`,
        method: 'GET',
        proxyFile: 'user-preferences.ts'
    },
    fetchUserPurchases: {
        apiRoute: 'user/:id/purchases',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/purchases${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-purchases.ts'
    },
    fetchUserReferralCommissions: {
        apiRoute: 'user/:id/referrer_commissions',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/referrer_commissions${paramString}`;
        },
        method: 'GET',
        proxyFile: 'referrals.ts'
    },
    fetchUserSales: {
        apiRoute: 'user/:id/sales',
        build({ queryParams = {}, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/sales${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-sales.ts'
    },
    fetchUserSubmittedModerationFlags: {
        apiRoute: 'user/:id/moderation_flags_submitted',
        build: ({ userId }: { userId: string }) => `user/${userId}/moderation_flags_submitted`,
        method: 'GET',
        proxyFile: 'moderation-flags.ts'
    },
    initiatePasswordRecovery: {
        apiRoute: 'user/initiate_password_reset',
        build: () => 'user/initiate_password_reset',
        method: 'POST',
        proxyFile: 'authorization.ts'
    },
    processPurchaseOfferSettlementPendingCheckout: {
        apiRoute: `user/:id/purchase_offer_financial_settlement/:settlementId/process_pending_payment`,
        build: ({ settlementId, loggedInUserId }: { settlementId: string; loggedInUserId: string }) =>
            `user/${loggedInUserId}/purchase_offer_financial_settlement/${settlementId}/process_pending_payment`,
        method: 'GET'
    },
    registerAccount: {
        apiRoute: 'user/register',
        build: () => 'user/register',
        method: 'POST',
        proxyFile: 'account.ts'
    },
    renewUserListing: {
        apiRoute: 'user/:id/listing/:listingId/renew',
        build: ({ listingId, userId }: { listingId: string; userId: string }) =>
            `user/${userId}/listing/${listingId}/renew`,
        method: 'GET',
        proxyFile: 'listings.ts'
    },
    resetUserPassword: {
        apiRoute: 'user/reset_password',
        build: () => 'user/reset_password',
        method: 'POST',
        proxyFile: 'authorization.ts'
    },
    updateActiveUserProfile: {
        apiRoute: 'user/:id/update_active_user_profile',
        build: ({ userId }: { userId: string }) => `user/${userId}/update_active_user_profile`,
        method: 'POST',
        proxyFile: 'account.ts'
    },
    updateListingById: {
        apiRoute: 'user/:id/user_profile/:userProfileId/listing/:listingId',
        build: ({ listingId, userId, userProfileId }: { listingId: string; userId: string; userProfileId: string }) =>
            `user/${userId}/user_profile/${userProfileId}/listing/${listingId}`,
        method: 'POST',
        proxyFile: 'listings.ts'
    },
    fetchUserListings: {
        apiRoute: 'user/:id/listings',
        build({ queryParams, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';
            return `user/${userId}/listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-listings.ts'
    },
    fetchUserViewedListings: {
        apiRoute: 'user/:id/viewed_listings',
        build({ queryParams, userId }: { queryParams: ParsedQuery; userId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';

            return `user/${userId}/viewed_listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-viewed-listings.ts'
    },
    updateListingSearchFilters: {
        apiRoute: 'user/:id/listing/:listingId/search_filters',
        build: ({ listingId, userId }: { listingId: string; userId: string }) =>
            `user/${userId}/listing/${listingId}/search_filters`,
        method: 'POST',
        proxyFile: 'listings.ts'
    },
    updateListingImagesSortOrder: {
        apiRoute: 'user/:id/listing/:listingId/images_sort',
        build: ({ listingId, userId }: { listingId: string; userId: string }) =>
            `user/${userId}/listing/${listingId}/images_sort`,
        method: 'POST',
        proxyFile: 'images.ts'
    },
    updateUserPersonalInfo: {
        apiRoute: 'user/:id/update_personal_info',
        build: ({ userId }: { userId: string }) => `user/${userId}/update_personal_info`,
        method: 'POST',
        proxyFile: 'account.ts'
    },
    updateUserPreferences: {
        apiRoute: 'user/:id/preferences',
        build: ({ userId }: { userId: string }) => `user/${userId}/preferences`,
        method: 'POST',
        proxyFile: 'user-preferences.ts'
    },
    updateUserTermsRequest: {
        apiRoute: 'user/:id/update_terms_agreement',
        build: ({ userId }: { userId: string }) => `user/${userId}/update_terms_agreement`,
        method: 'GET',
        proxyFile: 'user-terms.ts'
    },
    updateUserRating: {
        apiRoute: 'user/:id/rating/:ratingId',
        build: ({ raterId, ratingId }: { raterId: string; ratingId: string }) => `user/${raterId}/rating/${ratingId}`,
        method: 'PUT',
        proxyFile: 'ratings.ts'
    },
    uploadImages: {
        apiRoute: 'user/:id/image_uploads',
        build: ({ userId }: { userId: string }) => `user/${userId}/image_uploads`,
        method: 'POST',
        proxyFile: 'images.ts'
    },
    userReferrerBasicInfoRequest: {
        apiRoute: 'user/:id/referrer',
        build: ({ userId }: { userId: string }) => `user/${userId}/referrer`,
        method: 'GET',
        proxyFile: 'referrals.ts'
    },
    verifyUserAccount: {
        apiRoute: 'user/verify',
        build: () => 'user/verify',
        method: 'POST',
        proxyFile: 'authorization.ts'
    },

    // /user_profiles ROUTES
    createAddressFromProfileManager: {
        apiRoute: 'user_profiles/:userProfileId/address/new',
        build: ({ userProfileId }: { userProfileId: string }) => `user_profiles/${userProfileId}/address/new`,
        method: 'POST',
        proxyFile: 'user-profiles.ts'
    },
    deleteUserProfileAddressById: {
        apiRoute: 'user_profiles/:userProfileId/address/:addressId',
        build: ({ addressId, userProfileId }: { addressId: string; userProfileId: string }) =>
            `user_profiles/${userProfileId}/address/${addressId}`,
        method: 'DELETE',
        proxyFile: 'user-profiles.ts'
    },
    // fetchProfileAutocompleteResultsByUsername DEPRECATED
    fetchProfileAutocompleteResultsByUsername: {
        apiRoute: 'user_profiles/:userProfileId/address/:addressId',
        build({ searchString = '', onlyActiveSellers = true }: { searchString: string; onlyActiveSellers: boolean }) {
            const paramString = `?${querystring.stringify({
                username: searchString,
                ...(onlyActiveSellers ? { onlyActiveSellers: true } : {})
            })}`;
            return `user_profiles_autocomplete${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-profiles.ts'
    },
    fetchProfileDetailByProfileUsername: {
        apiRoute: 'user_profiles/:username/profile_detail',
        build: ({ username }: { username: string }) => `user_profiles/${username}/profile_detail`,
        method: 'GET',
        proxyFile: 'user-profiles.ts'
    },
    fetchProfileListingsByProfileId: {
        apiRoute: 'user_profile/:userProfileId/listings',
        build({ queryParams, userProfileId }: { queryParams: ParsedQuery; userProfileId: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';
            return `user_profile/${userProfileId}/listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-profiles.ts'
    },
    fetchProfileListingsByPage: {
        apiRoute: 'user_profiles/:username/listings',
        build({ queryParams, username }: { queryParams: ParsedQuery; username: string }) {
            const paramString =
                queryParams && Object.keys(queryParams).length > 0 ? `?${querystring.stringify(queryParams)}` : '';
            return `user_profiles/${username}/listings${paramString}`;
        },
        method: 'GET',
        proxyFile: 'user-profiles.ts'
    },
    fetchUserByUserProfileId: {
        apiRoute: 'user_profiles/:userProfileId/user',
        build: ({ userProfileId }: { userProfileId: string }) => `user_profiles/${userProfileId}/user`,
        method: 'GET',
        proxyFile: 'user-profiles.ts'
    },
    updateUserProfileAddressById: {
        apiRoute: 'user_profiles/:userProfileId/address/:addressId',
        build: ({ addressId, userProfileId }: { addressId: string; userProfileId: string }) =>
            `user_profiles/${userProfileId}/address/${addressId}`,
        method: 'PUT',
        proxyFile: 'user-profiles.ts'
    },
    fetchUserProfileDisplayGraphByUserProfileId: {
        apiRoute: 'user_profiles/:userProfileId/graph',
        build: ({ userProfileId }: { userProfileId: string }) => `user_profiles/${userProfileId}/graph`,
        method: 'GET',
        proxyFile: 'user-profiles.ts'
    },
    removeUserFavoriteListings: {
        apiRoute: 'user/:id/add_favorite_listings',
        build: ({ userId }: { userId: string }) => `user/${userId}/remove_favorite_listings`,
        method: 'POST',
        proxyFile: 'user-favorite-listings.ts'
    },
    updateUserProfileById: {
        apiRoute: 'user_profiles/:userProfileId',
        build: ({ userProfileId }: { userProfileId: string }) => `user_profiles/${userProfileId}`,
        method: 'POST',
        proxyFile: 'user-profiles.ts'
    }
};

// COLORS
const colorReset = '\u001B[0m';
// Bright = "\x1b[1m"
const dim = '\u001B[2m';
// Underscore = "\x1b[4m"
// Blink = "\x1b[5m"
// Reverse = "\x1b[7m"
// Hidden = "\x1b[8m"

// FgBlack = "\x1b[30m"
// FgRed = "\x1b[31m"
const fgGreen = '\u001B[32m';
const fgYellow = '\u001B[33m';
// const fgBlue = '\x1b[34m';
// FgMagenta = "\x1b[35m"
const fgCyan = '\u001B[36m';
// FgWhite = "\x1b[37m"

// BgBlack = "\x1b[40m"
// BgRed = "\x1b[41m"
// BgGreen = "\x1b[42m"
// BgYellow = "\x1b[43m"
// BgBlue = "\x1b[44m"
// BgMagenta = "\x1b[45m"
// BgCyan = "\x1b[46m"
// BgWhite = "\x1b[47m"

export const logUiRouteSupport = (logger: pino.Logger) => {
    const allRoutes = Object.keys(PROXY_ROUTES)
        .sort()
        .map((proxyMethodName) => {
            const { apiRoute, method: restMethod, proxyFile = '' } = PROXY_ROUTES[proxyMethodName];
            const proxyMethodFillString = Array.from({ length: 38 - proxyMethodName.length })
                .join(' ')
                .toString();
            const restFillString = Array.from({ length: 8 - restMethod.length })
                .join(' ')
                .toString();
            return `${fgCyan}${proxyMethodName}${proxyMethodFillString} ${fgGreen}[${restMethod}]${restFillString} ${fgYellow}${apiRoute}${colorReset} ${dim}(${proxyFile})${colorReset}`;
        });

    logger.info({
        msg: ['\nUI SUPPORTS THE FOLLOWING API ROUTES:\n', ...allRoutes].join('\n  ')
    });
};
