import { CartSalesTaxData, ShoppingCartData } from '@bladebinge/types';
import { normalizedFetch } from '../utils/normalized-fetch';
import { PROXY_ROUTES } from '../ui-proxy-routes';

export const getCalculatedCartSalesTax = async ({
    cartData,
    includeFinancingProcessingFee,
    includeShippingInsurance,
    signal
}: {
    cartData: ShoppingCartData;
    includeFinancingProcessingFee: boolean;
    includeShippingInsurance: boolean;
    signal?: AbortSignal;
}): Promise<{
    cartId: string;
    cartTaxData: CartSalesTaxData;
}> => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.fetchCalculatedSalesTax.build(),
        options: {
            method: 'POST',
            body: JSON.stringify({
                cartData,
                includeFinancingProcessingFee,
                includeShippingInsurance
            }),
            signal,
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage = '' } = {} } = response;

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
