import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import type {
    FlaggableEntityTypes,
    ModerationFlagFormState,
    ModerationFlagPostJson,
    ModerationFlagTypes,
    ModerationFlagWithHydratedRelatedEntities
} from '@bladebinge/types';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';
import { DB_SHORT_DESCRIPTION_MAX } from '@bladebinge/web-service-common/src/constants/database-constants';
import { MODERATION_FLAG_TYPES } from '@bladebinge/web-service-common/src/constants/moderation-flags';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { FlaggingGuidelinesModal } from '../../components/atoms/FlaggingGuidelinesModal';
import { StyledFormMessagingWrapper, StyledFullWidthForm, StyledHookFormErrorMessage } from '../styled-shared';
import { createModerationFlagRequest } from '../../server/api-proxy/ui-mutation-fns/moderation-flags';

export const ModerationFlagForm = ({
    flaggedByUserId,
    flaggedEntityId,
    flaggedEntityOwnerId = '',
    flaggedEntityType,
    onClose
}: {
    readonly flaggedByUserId: string;
    readonly flaggedEntityId: string;
    readonly flaggedEntityOwnerId?: string;
    readonly flaggedEntityType: FlaggableEntityTypes;
    readonly onClose: () => void;
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const createFlagMutation = useMutation({
        mutationFn: createModerationFlagRequest,
        onSuccess(flagGraph) {
            queryClient.setQueryData(
                uiCacheKeyBuilderMap.loggedInUserModerationFlagsSubmitted({ loggedInUserId: flaggedByUserId }),
                (oldData: ModerationFlagWithHydratedRelatedEntities[] | undefined = []) =>
                    oldData.concat(flagGraph as ModerationFlagWithHydratedRelatedEntities)
            );
        }
    });
    const {
        error: createModerationFlagError,
        isPending: isCreatingFlag,
        isSuccess: showSuccessMessage
    } = createFlagMutation;

    const [isFlagTypeSelectorOpen, setIsFlagTypeSelectorOpen] = useState<boolean>(false);

    const {
        formState: { errors, isDirty },
        getValues,
        handleSubmit,
        register,
        setValue
    } = useForm<ModerationFlagFormState>({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
        defaultValues: {
            flaggedByUserId,
            flaggedEntityId,
            flaggedEntityOwnerId,
            flaggedEntityType,
            flaggerComment: '',
            flagType: ''
        }
    });

    const closeAfterSuccess = (_e: React.SyntheticEvent<Element, Event>) => {
        _e.stopPropagation();
        _e.preventDefault();
        onClose();
    };

    const clearFormError = (_e: React.SyntheticEvent<Element, Event>) => {
        _e.stopPropagation();
        _e.preventDefault();
        createFlagMutation.reset();
    };

    const closeFlagTypeSelector = useCallback(() => setIsFlagTypeSelectorOpen(false), []);
    const openFlagTypeSelector = useCallback(() => setIsFlagTypeSelectorOpen(true), []);

    const handleFlagSubmission = (moderationFlag: ModerationFlagFormState) => {
        if (!isDirty) {
            return;
        }

        createFlagMutation.mutate(moderationFlag as ModerationFlagPostJson);
    };

    const handleSelectFlagType = useCallback(
        (event: SelectChangeEvent<string>) => {
            const flagType = event.target.value ?? '';
            const validFlagType = MODERATION_FLAG_TYPES.includes(flagType as string) ? flagType : '';
            setValue('flagType', validFlagType as ModerationFlagTypes, { shouldDirty: true });
        },
        [setValue]
    );

    // hook form material UI register calls
    const { ref: flaggerCommentRef, ...flaggerCommentRest } = register('flaggerComment', {
        maxLength: DB_SHORT_DESCRIPTION_MAX,
        required: true
    });

    return (
        <StyledFullWidthForm onSubmit={handleSubmit(handleFlagSubmission)}>
            <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                {t('common:moderation_flags.flagging_form')}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                {t('common:moderation_flags.moderation_flagging_disclaimer')}
            </Typography>
            {showSuccessMessage && (
                <Grid size={9}>
                    <StyledFormMessagingWrapper>
                        <Alert onClose={closeAfterSuccess} severity="success">
                            {t('common:moderation_flags.you_have_flagged_this_item')}
                        </Alert>
                    </StyledFormMessagingWrapper>
                </Grid>
            )}
            <Grid container>
                <Grid size={12}>
                    <Typography variant="body2" sx={{ mb: 1, fontWeight: 'bold' }}>
                        {t(`common:moderation_flags.flagging_entity_type.${flaggedEntityType}`)}
                    </Typography>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12
                    }}
                >
                    <FlaggingGuidelinesModal />
                </Grid>
                <FormControl fullWidth>
                    <InputLabel id="flag_type_selector_label" sx={{ ml: 2, fontFamily: headingFont.style.fontFamily }}>
                        {t('common:moderation_flags.select_flag_type')}
                    </InputLabel>
                    <Select
                        className={errors.flagType ? 'input-error' : ''}
                        id="flag_type_selector"
                        fullWidth
                        labelId="flag_type_selector_label"
                        open={isFlagTypeSelectorOpen}
                        onChange={handleSelectFlagType}
                        onClose={closeFlagTypeSelector}
                        onOpen={openFlagTypeSelector}
                        value={getValues('flagType')}
                        variant="outlined"
                    >
                        {MODERATION_FLAG_TYPES.map((flagType) => (
                            <MenuItem key={flagType} value={flagType}>
                                <Typography variant="body1">
                                    {t(`common:moderation_flags.flag_types.${flagType}`) ?? ''}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                    <input id="flagType" {...register('flagType', { required: true })} type="hidden" />
                    <StyledHookFormErrorMessage
                        as="div"
                        errors={errors}
                        message={t('common:moderation_flags.errors.flag_type_error')}
                        name="flagType"
                    />
                </FormControl>
            </Grid>
            <Grid container justifyContent="flex-end" spacing={1}>
                <Grid size={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        autoComplete={t('common:moderation_flags.comment')}
                        className={errors.flaggerComment ? 'input-error' : ''}
                        fullWidth
                        id="flaggerComment"
                        inputRef={flaggerCommentRef}
                        label={t('common:moderation_flags.comment')}
                        placeholder={t('common:moderation_flags.comment')}
                        margin="normal"
                        minRows={3}
                        maxRows={5}
                        multiline
                        variant="outlined"
                        {...flaggerCommentRest}
                    />
                    <StyledHookFormErrorMessage
                        as="div"
                        errors={errors}
                        message={t('common:moderation_flags.errors.comment_error')}
                        name="flaggerComment"
                    />
                </Grid>
                {createModerationFlagError && (
                    <Grid size={9}>
                        <StyledFormMessagingWrapper>
                            <Alert onClose={clearFormError} severity="error">
                                {createModerationFlagError.message}
                            </Alert>
                        </StyledFormMessagingWrapper>
                    </Grid>
                )}
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid
                    size={{
                        sm: 6,
                        xs: 8
                    }}
                >
                    {showSuccessMessage ? (
                        <Button
                            color="inherit"
                            fullWidth
                            size="large"
                            sx={{
                                mt: 2,
                                mx: 0,
                                mb: 3
                            }}
                            onClick={closeAfterSuccess}
                            variant="contained"
                        >
                            {t('common:general.close')}
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            disabled={isCreatingFlag || Boolean(createModerationFlagError)}
                            fullWidth
                            size="large"
                            sx={{
                                mt: 2,
                                mx: 0,
                                mb: 3
                            }}
                            type="submit"
                            variant="contained"
                        >
                            {t('common:moderation_flags.submit_flag')}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </StyledFullWidthForm>
    );
};
