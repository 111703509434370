import { normalizedFetch } from '../utils/normalized-fetch';
import { PROXY_ROUTES } from '../ui-proxy-routes';

export const deleteUserInterfaceNotification = async (
    {
        notificationId,
        userId
    }: {
        notificationId: string;
        userId: string;
    },
    signal?: AbortSignal
) => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.deleteUserInterfaceNotification.build({ notificationId, userId }),
        options: {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage = '' } = {} } = response;

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};

export const bulkDismissUserInterfaceNotifications = async (
    {
        notificationIds,
        userId
    }: {
        notificationIds: string[];
        userId: string;
    },
    signal?: AbortSignal
) => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.bulkDismissUserInterfaceNotifications.build({ userId }),
        options: {
            method: 'POST',
            body: JSON.stringify({ notificationIds }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage = '' } = {} } = response;

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
