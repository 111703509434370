import { normalizedFetch } from '../utils/normalized-fetch';
import { PROXY_ROUTES } from '../ui-proxy-routes';

export const getCalculatedOfferCheckoutSalesTax = async ({
    loggedInUserId,
    settlementId,
    useFinancing,
    signal
}: {
    loggedInUserId: string;
    settlementId: string;
    useFinancing: boolean;
    signal?: AbortSignal;
}) => {
    const response = await normalizedFetch({
        endpoint: PROXY_ROUTES.fetchCalculatedOfferCheckoutSalesTax.build({
            loggedInUserId,
            settlementId
        }),
        options: {
            method: 'POST',
            body: JSON.stringify({
                useFinancing
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    });

    const { error: { message: apiErrorMessage = '' } = {} } = response;

    if (apiErrorMessage) {
        throw new Error(apiErrorMessage);
    }

    return response;
};
